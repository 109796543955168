import React from "react"

import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import Content from "../../sections/services/Content"
import { t } from "@lingui/macro"

const Services = ({ location }) => {
  return (
    <>
      <PageWrapper>
        <SEO
          pathname={location.pathname}
          title={t`Leistungen`}
          description={""}
        />
        <Content />
      </PageWrapper>
    </>
  )
}
export default Services
