import React, { useContext } from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import GlobalContext from "../../context/GlobalContext"

import imgEngineering from "../../assets/image/svg/service-engineering.svg"
import imgConsulting from "../../assets/image/svg/service-consulting-4.svg"
import imgContracting from "../../assets/image/svg/service-contracting-5.svg"

const Content = () => {
  const gContext = useContext(GlobalContext)

  return (
    <>

      <div className="tw-px-4 tw-py-24 tw-mt-8 tw-bg-white section md:tw-mt-16">

        <h1 className="tw-my-16 tw-mt-3 tw-text-6xl tw-font-bold tw-text-left tw-text-black tw-font-title sm:tw-text-center">Services</h1>

        <div className="tw-container tw-mx-auto lg:tw-max-w-4xl">
          <p className="tw-mt-3 tw-text-left lg:tw-text-center">
            Kunden schätzen AMAI für das vollumfängliche Leistungsspektrum auf einem hohen Niveau. Sowohl etablierte Unternehmen, die sich für die Digitale Transformation entschieden haben, als auch zukünftige Key Player mit einem starken Wachstumsanspruch setzen auf AMAI, um ihre digitalen Vorhaben strategisch und operativ umzusetzen.
          </p>
        </div>

      </div>



      <div className="tw-py-16 tw-pb-24 section tw-bg-amaidarkblue">
        <div className="tw-container tw-px-4 tw-mx-auto">
          <div className="tw-mb-12">
            <h3 className="tw-text-base tw-text-center tw-text-blue-100 tw-text-opacity-80">UNSERE LEISTUNGEN</h3>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-0 tw-shadow-xl lg:tw-grid-cols-3">


            <div> {/* Consulting */}
              <Link className="tw-block tw-h-full tw-p-8 tw-bg-white tw-group lg:tw-rounded-l-md lg:tw-rounded-none tw-rounded-t-md" to="/services/consulting">
                <div className="tw-w-10 tw-h-10">
                  <img src={imgConsulting} alt="" className="tw-object-contain tw-object-bottom tw-h-full" />
                </div>

                <h3 className="tw-mt-3 tw-text-xl tw-font-bold tw-text-black">Consulting</h3>

                <p className="tw-mt-4 tw-text-base tw-text-darkShade">
                  KI-Projekte sind mit besonderen Herausforderungen verbunden. Wir unterstützen Unternehmen dabei, Unternehmensprozesse "KI-ready" zu gestalten, sinnvolle Datenstrategien zu entwickeln und Projekte zielführend zu strukturieren, um auf diesem Feld erfolgreich zu sein und Data Maturity zu erreichen.
                </p>

                <p className="tw-mt-6 tw-mb-0 tw-text-sm tw-transition tw-text-amaiblue">
                  Beratungsangebot&nbsp; <span className="tw-inline-block tw-transition-transform tw-transform group-hover:tw-translate-x-2">→</span>
                </p>
              </Link>
            </div>

            <div> {/* Engineering */}
              <Link className="tw-block tw-h-full tw-p-8 tw-bg-white tw-group" to="/services/engineering">
                <div className="tw-w-10 tw-h-10">
                  <img src={imgEngineering} alt="" className="tw-object-contain tw-object-bottom tw-h-full" />
                </div>

                <h3 className="tw-mt-3 tw-text-xl tw-font-bold tw-text-black">Engineering</h3>

                <p className="tw-mt-4 tw-text-base tw-text-darkShade">
                  Das Entwickeln und Trainieren von KI-Systemen ist eine komplexe Aufgabe. Wir entwickeln unternehmens-spezifische End-to-End Lösungen. Ob On-Premises-, Edge- oder Cloud-Einsatz, wir reagieren flexibel auf alle Kundenanforderungen.
                </p>

                <p className="tw-mt-6 tw-mb-0 tw-text-sm tw-transition tw-text-amaiblue">
                  Kundenspezifische KI Entwicklung&nbsp; <span className="tw-inline-block tw-transition-transform tw-transform group-hover:tw-translate-x-2">→</span>
                </p>
              </Link>
            </div>

            <div> {/* Contracting */}
              <Link className="tw-block tw-h-full tw-p-8 tw-bg-white tw-group lg:tw-rounded-r-md lg:tw-rounded-l-none tw-rounded-b-md" to="/services/contracting">
                <div className="tw-w-10 tw-h-10">
                  <img src={imgContracting} alt="" className="tw-object-contain tw-object-bottom tw-h-full" />
                </div>

                <h3 className="tw-mt-3 tw-text-xl tw-font-bold tw-text-black">Contracting</h3>

                <p className="tw-mt-4 tw-text-base tw-text-darkShade">
                  KI-Experten mit praktischen Kenntnissen sind schwer zu finden. Wir unterstützen Kundenprojekte und ergänzen existierende Teams mit dem notwendigen, erfahrenen Data Science Personal on Demand – vor Ort und Remote.
                </p>
                <p className="tw-mt-6 tw-mb-0 tw-text-sm tw-transition tw-text-amaiblue">
                  Verstärken Sie Ihr Team&nbsp; <span className="tw-inline-block tw-transition-transform tw-transform group-hover:tw-translate-x-2">→</span>
                </p>

              </Link>
            </div>

          </div>
        </div>
      </div>



      <div className="tw-bg-gray-100 section tw-py-36">
        <div className="tw-container tw-px-4 tw-mx-auto lg:tw-max-w-7xl">
          <div className="tw-mb-12">
            <h3 className="tw-text-base tw-text-center tw-text-gray-500">UNSERE PRINZIPIEN</h3>
          </div>
          <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 lg:tw-grid-cols-3 md:tw-gap-16">

            <div>
              <p className="tw-font-medium">
                Agilität
              </p>
              <p className="tw-text-base">
                Machine Learning ist eine neue Technologie, die innovative Organisations- und Arbeitsformen mit sich bringt. AMAI arbeitet nach agilen Prinzipien wie Scrum und Kanban und gewährleistet damit eine hohe Projekt-Performance. Wir sind flexibel und anpassungsfähig und bereichern die Organisation unserer Kunden von Tag 1.
              </p>
            </div>

            <div>
              <p className="tw-font-medium">
                Innovativ und Robust
              </p>
              <p className="tw-text-base">
                AMAI folgt dem eigenen Anspruch der Herstellung eine Balance zwischen dem aktuellen Stand der angewandten Forschung und maximaler Robustheit intelligenter Software. Für Sie ergeben sich daraus erhebliche Vorteile in Wirtschaftlichkeit und Wettbewerb.
              </p>
            </div>
            <div>
              <p className="tw-font-medium">
                Individuelle Entwicklung
              </p>
              <p className="tw-text-base">
                Im Unterschied zur Standardsoftware, die Betriebe dazu zwingt bereits bewährte interne Prozesse neu auszurichten, entwickelt AMAI Individualsoftware, die sich nahtlos in das Bestehende einfügt. Einer unserer Stärken ist das tiefe Verstehen von Technologien, Geschäftsmodellen und Mentalitäten unserer Kunden.
              </p>
            </div>
            <div>
              <p className="tw-font-medium">
                AI Full-Stack Team
              </p>
              <p className="tw-text-base">
                Erfolgreiche ML-Projekte erfordern Teams und Individuen mit langjähriger Erfahrung und ausgereiften Fähigkeiten. Da wir über eine breite Rollenvielfalt verfügen, können Sie AMAI-Experten in den jeweiligen Projektphasen wie Konzeption, Entwicklung und Betrieb flexibel einsetzt.
              </p>
            </div>
            <div>
              <p className="tw-font-medium">
                Building Blocks
              </p>
              <p className="tw-text-base">
                AMAI verfügt über umfangreiche Erfahrung im Umgang mit verschiedenartigen ML-Technologien insbesondere auf den Feldern Computer Vision, Natural Language Processing und Understanding. Durch intern standardisierte "Building Blocks", können wir auf Komponenten und die Erfahrungen aus vergangenen Projekten zurückgreifen.
              </p>
            </div>

            <div>
              <p className="tw-font-medium">
                Datenschutz und Ethik
              </p>
              <p className="tw-text-base">
                Das Einhalten europäischer Rechtsnormen, Selbstverpflichtungen und eine professionelle Serverinfrastruktur mit höchsten Sicherheitsanforderungen sind gegenwärtiger Standard im Umgang mit Daten vor dem Hintergrund humanzentrierter ML-Systeme. AMAI ist Mitglied im KI-Bundesverband und dem Digital Hub Karlsruhe, die jeweils für ihre hohen Standards bekannt sind.
              </p>
            </div>

          </div>
        </div>
      </div>








    </>
  )
}

export default Content
